export const translations = {
  no_results: "Ei tuloksia",
  error_warning: "Jotain meni pieleen",
  no_items: "Ei näytettävää",
  download_csv: "Lataa luettelo",
  send_via_email: "Lähetä sähköpostilla",
  temporary_unavailable: "Väliaikaisesti ei saatavilla",
  support_email: "laskut@handlarfinans.fi",
  sweden: "Sweden",
  finland: "Finland",
  dashboard: {
    dashboard: "Etusivu",
    summary: "Yhteenveto",
    stats: "Tilastot",
    pending_deals: "Vireillä olevat sopimukset",
    in_inventory: "Varastossa",
    avg_inventory_days: "Keskimääräiset varastopäivät",
    total_deals_made: "Tehtyjä kauppoja yhteensä",
    financing: "Rahoitettu",
    overdue: "Erääntynyt",
    performing: "Ei erääntynyt",
    used: "käytössä",
    from: "limiitin kokonaismäärä",
    reserved: "reserved",
  },
  sidebar: {
    dashboard: "Etusivu",
    deals: "Sopimukset",
    support: "Support FI",
    kyc: "Tuki",
    deal_status: {
      pending: "Vireillä",
      active: "Aktiiviset",
      settled: "Lunastetut",
    },
    transactions: "Suoritetut maksut",
    inventory: "Inventory",
    consumer_loans: "Consumer Loans",
    users_management: "Users Management",
    users: "Users",
    invites: "Invites",
    settings: "Settings",
    company_details: "Company details",
  },
  placeholders: {
    labels: "Labels",
    save: "Tallenna",
    all_content_types: "All content types",
    downpayment_label: "Down payment (optional)",
    downpayment: "Down payment",
    first_name: "First name",
    last_name: "Last name",
    phone: "Puhelin",
    email: "Sähköposti",
    phone_no: "Puhelinnumero",
    filter: "Suodata",
    user: "Käyttäjä",
    vin_reg_no: "Runkonumero / Rek. numero",
    reg_vin: "Rek. / Runkonumero",
    reg_no: "Rek. numero",
    vin: "Runkonumero",
    make: "Merkki",
    model: "Malli",
    make_model: "Merkki & Malli",
    model_year: "Model year",
    cancel: "Peruuta",
    upload: "Lataa",
    price: "Hinta",
    purchase_price_net: "Ostohinta ilman ALV",
    purchase_price_vat: "ALV",
    dummy_no: "XX,XX",
    mileage: "Mittarilukema",
    additional_info: "Lisätiedot",
    your_comment: "Kommenttisi",
    not_done: "Ei suoritettu",
    generate: "Luo",
    files: "Tiedostoja",
    fetch: "Hae",
    fetch_info: "Hae tiedot",
    confirm: "Vahvista",
    add_comments: "Lisää kommenttisi tähän...",
    submit: "Lähetä",
    exit: "Poistu",
    created_at: "Luotu",
    created_before: "Created before",
    created_after: "Created after",
    updated_at: "Updated at",
    status: "Tila",
    seller: "Myyjä",
    save_draft: "Tallenna luonnos",
    person: "Henkilö",
    organisation: "Yritys",
    entity_type: "Tyyppi",
    org_no: "Y-tunnus",
    ssn: "Henkilötunnus",
    ssn_long: "Henkilötunnus",
    contact_name: "Nimi",
    address: "Osoite",
    contact: "Yhteystiedot",
    additional_contact: "Lisää yhteystieto",
    name: "Nimi",
    country_id: "Maa",
    select_country: "Valitse maa",
    individual: "Yksilö",
    recipient: "Vastaanottaja",
    details: "Lisätiedot",
    export: "Vie",
    date: "Päivämäärä",
    company_name: "Yrityksen nimi",
    post_address: "Osoite",
    postal_code: "Postinumero",
    city: "Kaupunki",
    street: "Katuosoite",
    close: "Sulje",
    vehicle: "Ajoneuvo",
    iban: "IBAN",
    swift: "SWIFT",
    bic: "BIC",
    account_number: "Account number",
    clearing_number: "Clearing number",
    payment_ref: "Viite",
    remove: "Remove",
    select: "Select",
    location: "Location",
    edit: "Edit",
    yes: "Kyllä",
    no: "Ei",
    or: "or",
    preview_file: "Preview file",
    costs: "Costs",
    none: "-",
    invoice: "Laskun",
    state: "State",
    any: "Any",
    countries: "Countries",
    search_country: "Search country",
    country_or_residence: "Country of residence",
    continue: "Continue",
    back: "Back",
    all: "All",
    filter_table: "Filter table",
    export_csv_info:
      "Exports usually take about 30 minutes to compile. We will send you an email when the file is ready.",
    export_successfully_requested: "Export successfully requested",
    export_error: "Export could not be requested",
  },
  deals: {
    overdue: "Erääntynyt",
    due_days: "{{days}} päivää",
    one_due_day: "1 päivä",
    payment_overdue: "Payment overdue",
    successfully_created: "Sopimus luotu onnistuneesti",
    create_error_warning: "Sopimuksen luonti epäonnistui, olettehan meihin yhteydessä",
    change_deal_success: "Sopimus päivitetty",
    change_deal_error: "Päivitys epäonnistui, olettehan yhteydessä meihin",
    add_new_deal: "Uusi sopimus",
    add_new_deal_success: "Sopimus luotu",
    add_new_deal_error: "Sopimuksen luonti epäonnistui, olettehan meihin yhteydessä",
    action_required_message: "Sinulta tarvitaan toimenpidettä",
    continue: "Jatka",
    go_to_deal: "Siirry sopimukseen",
    active_deal: "Aktiivinen sopimus",
    vat_deductible: "Alv-vähennyskelpoinen",
    vat_nondeductible: "Alv-vähennyskelvoton",
    margin_scheme: "Marginaaliverollinen",
    registered_owner: "Rekisteröity omistaja",
    marketplace: "Markkinapaikka",
    own_stock: "Oma varasto",
    proceed: "Jatka",
    request_financing: "Hae rahoitusta",
    fetch_error: "Sopimuksen haku epäonnistui, olettehan meihin yhteydessä",
    deals: "Sopimukset",
    no_deal: "Sopimusta ei löytynyt",
    ownership: "Omistajanvaihdos",
    payment_required: "Maksua vaaditaan",
    missing_data: "Tiedot puuttuu",
    exit_deal: "Poistu",
    exit_deal_unsaved_changes: "Sinulla on tallentamattomia muutoksia. Jos poistut nyt, muutokset menetetään",
    data_room: "Tiedostot",
    advance: "Ennakko",
    request_offer_success: "Rahoitushakemuksesi on lähetetty",
    request_offer_error: "Jotain meni pieleen hakemuksessasi, ota meihin yhteyttä",
    financed_amount: "Rahoitettu määrä",
    inventory_days: "Varastopäivät",
    payment_due_in: "Maksu erääntyy",
    export_transaction_report: "Export transaction report",
    export_transaction_report_success: "Transaction report was successfully generated.",
    export_transaction_report_error: "Something went wrong while generating the transaction report.",
    export_transaction_report_warning: "Couldn't generate the transaction report.",
    deal_status: {
      initiated: "Luonnos",
      offer_requested: "Rahoitus haettu",
      pending: "Vireillä",
      offer_accepted: "Hyväksytty",
      prepared: "Laadittu",
      servicing: "Aktivoitu",
      cancelled: "Peruutettu",
      settled: "Lunastettu",
    },
    deal_sections: {
      vehicle: "Ajoneuvo",
      dealer: "Liike",
      purchase: "Osto",
      offering: "Tarjous",
      payout: "Maksu",
      ownership: "Omistus",
      servicing: "Aktiivinen",
      contracts: "Sopimukset",
      payments: "Maksut",
    },
    export_deals: "Export all deals",
    cancel_deal: "Cancel deal",
    cancel_deal_prompt: "Are you sure you want to cancel the deal?",
    cancel_deal_success: "Deal was successfully cancelled",
    cancel_deal_error: "Deal could not be cancelled",
  },
  files: {
    last_updated: "Last updated",
    file: "File",
    download_error: "Something went wrong while downloading the file",
    select_content_type: "Select content type",
    preview: "Esikatsele",
    files: "Tiedostot",
    create_update_at: "Luotu/päivitetty:",
    owner: "Omistaja",
    file_name: "Tiedoston nimi",
    size: "Koko",
    no_file: "Ei tiedostoja",
    success_message: "Tiedosto poistettu",
    error_message: "Tiedostoa ei voida poistaa",
    download: "Lataa",
    delete: "Poista",
    upload_failed: "- Lataus epäonnistui, yritä uudelleen",
    replace_file: "Korvaa tiedosto",
    delete_file: "Poista tiedosto",
    delete_warning: "Olet poistamassa tiedostoa {{fileName}}. Oletko varma?",
    files_page: "Tiedostoja",
    upload_purchase_invoice: "Lisää ostolaskut",
    drag_drop: "Vedä & pudota tiedostosi tähän, tai paina selataksesi tiedostoja",
    upload_receipt: "Please upload your payment receipt using the payment details above",
    upload_file: "Lisää tiedosto",
    tag: "Kategoria",
    successfully_uploaded: "Tiedosto(ja) lisätty",
    error_on_uploaded: "Jokin meni pieleen lisätessä tiedostoa {{fileName}} ",
    error_display_file_type: "Tätä tiedostomuotoa ei voida näyttää",
    labels: {
      general_data: "Yleistiedot",
      defects: "Viat",
      proforma_invoice: "Proforma-lasku",
      supplier_invoice: "Ostolasku",
      image: "Kuva",
      pdf: "PDF",
      purchase_contract: "Kauppasopimus",
      ownership_paper: "Omistustodistus",
      proof_of_vehicle: "Ajoneuvotodistus",
      contract_base: "Sopimus",
      all: "All",
      report: "Report",
      finance: "Finance",
      advance_invoice: "Advance invoice",
      purchase_document: "Purchase document",
      contract: "Contract",
      receipt: "Receipt",
      payout_receipt: "Payout receipt",
      advance_receipt: "Advance receipt",
      ownership: "Ownership",
      servicing: "Servicing",
      vin: "VIN",
      kyc: "KYC",
      customer_invoice: "Customer invoice",
      invoice: "Invoice",
    },
    content_types: {
      "application/pdf": "PDF",
      "text/csv": "CSV",
      "image/jpeg": "JPEG",
      "image/png": "PNG",
    },
    owner_types: {
      internal_user: "Internal user",
      external_user: "External user",
      system: "System",
    },
  },
  purchase: {
    indicative_signatory: "Seller signatory",
    financier: "Rahoittaja",
    seller: "Myyjä",
    contract_parties: "Sopimusosapuolet",
    labels: {
      debt_amount: "Loppuvelka",
      debt_vat: "ALV loppuvelka",
      debt_note: "Lisää lunastustodistus loppuvelasta",
      payment_method: "Maksutapa",
      amount: "Summa",
    },
    trade_in: "Vaihtokohde",
    trade_in_check: "Tähän kauppaan kuuluu vaihtokohde",
    sales_price: "Myyntihinta",
    invoice_prompt: "Onko sinulla jo laskua/kauppakirjaa tästä ostosta?",
    yes: "Kyllä",
    no_invoice: "Ei, haluan luoda kauppasopimuksen",
    invoice_info: "Voit aina lähettää sähköpostia osoitteeseen: ",
    payment_information: "Maksutiedot",
    invoice: {
      invoice_modal_title: "Laskun tiedot: {{total}}",
      view_invoice: "Katso",
      back_to_invoice: "Takaisin",
    },
  },
  offering: {
    requested_financing_amount: "Requested financing amount",
    max_financing_amount: "Max financing amount is {{max_amount}}",
    financing_amount_update_failed: "Something went wrong while updating the financing amount",
    financing_amount_updated: "Financing amount was successfully updated",
    invalid_financing_amount: "Invalid financing amount",
    change_financing_amount: "Change financing amount",
    estimated_price: "Arvioitu myyntihinta",
    requested_financing: "Haettu rahoitussumma",
    financing_offer: "Tarjottu rahoitus",
    total_purchase_price: "Ostohinta kokonaisuudessaan",
    financing_rate: "Rahoitusaste",
    financing_amount: "Rahoitettava osuus",
    advance_amount: "Ennakko-osuus",
    monthly_interest: "Kuukausikorko",
    standard_inv_financing: "Varastorahoitus",
    choose_financing: "Valitse rahoitussumma",
    deal_received: "Hakemuksesti on vastaanotettu",
    checking_deal: "Tarkistamme nyt kaikki tiedot ja suoritamme hinnoittelun. Palataan kun tämä valmistuu.",
    contact_us: "Ota meihin yhteyttä",
    accept_offer: "Hyväksy tarjous",
    accept_offer_success: "Tarjous hyväksytty",
    accept_offer_error: "Jokin meni pieleen tarjousta hyväksyttäessä",
    update_deal: "Update deal",
    abort: "Abort",
    abort_success: "Offer was successfully aborted",
    abort_error: "Something went wrong while aborting the offer",
    check_deal_status: "Check deal status",
  },
  vehicle: {
    description: "Description",
    successfully_created: "The vehicle was successfully created",
    create_error_warning: "Ajoneuvoa ei voitu luoda",
    no_vehicle_added: "Ajoneuvoa ei lisätty",
    successfully_edited: "Ajoneuvo päivitetty",
    edit_error_warning: "Päivitys epäonnistui",
    first_reg_date: "Ensirekisteröintipäivä",
    current_registration: "Current registration",
    fuel_type: "Fuel type",
    fuel_types: {
      petrol: "Petrol",
      diesel: "Diesel",
      electric: "Electric",
      gas: "Gas",
      hybrid: "Hybrid",
      e85: "E85",
      other: "Other",
    },
    drive_type: "Drive type",
    drive_types: {
      FWD: "FWD",
      RWD: "RWD",
      AWD: "AWD",
    },
    transmission: "Transmission",
    transmission_types: {
      manual: "Manual",
      automatic: "Automatic",
      other: "Other",
    },
    power: "Power",
    VAT_status: "VAT Status",
    VAT_status_undefined: "VAT status undefined",
    deductible: "VAT Deductible",
    margin_scheme: "Margin scheme",
    current_owner: "Current owner",
    estimated_sales_price: "Odotettu myyntihinta",
    information: "Tiedot",
    fetch_vehicle_info: "Hae ajoneuvotiedot",
    import_error: "Ajoneuvon tuonti epäonnistui",
    additional_info: "Lisätiedot",
    additional_info_description:
      "Kerro lisää ostettavasta ajoneuvosta, esim. kunto, varusteet tai jotain muuta, mikä erottaa ajoneuvon",
    upload_img_doc: "Lisää kuvia/kuntoraportti",
    search_placeholder: "Search vehicle",
    additional_comment: "Additional comment",
    vehicle_info: "Vehicle info",
    no_of_keys_received: "No. of keys received",
    no_of_keys_received_required: "No. of keys received is required",
    additional_tyres_included: "Additional tyres included",
    add_vehicle: {
      search_add_label: "Search or add new vehicle",
      add_new: "Add new vehicle",
    },
    vehicle_type: "Vehicle type",
    vehicle_types: {
      car: "Car",
      boat: "Boat",
    },
    win: "WIN",
    type_of_boat: "Type of boat",
    boat_types: {
      motor_ship: "Motor ship",
      sail_ship: "Sail ship",
      other: "Other",
    },
    engine_serial_number: "Engine serial number",
    engine_model: "Engine model",
    engine_manufacturing_year: "Engine manufacturing year",
    motor_identification: "Motor identification",
  },
  contracts: {
    add_contract_error: "Add contract error",
    contract_signing_initiated_success: "Sopimus luotu ja lähetetty allekirjoitettavaksi",
    signatories: "Allekirjoittavat osapuolet",
    contract_preview: "Esikatselu",
    purchase_contract: "Ostoasiakirjat",
    generate_contract: "Luo sopimus",
    financing_contract: "Rahoitussopimus",
    other_documents: "Muut asiakirjat",
    sellers: "Sellers",
    buyers: "Buyers",
    status: {
      init: "Luonnos",
      pending: "Odottaa",
      closed: "Suljettu",
      cancelled: "Keskeytetty",
      expired: "Vanhentunut",
      rejected: "Hylätty",
      error: "Virhe",
      delivered: "Delivered",
      viewed: "Viewed",
      signed: "Signed",
    },
    no_signee: "No designated signer",
  },
  payments: {
    pay_advance: "Maksa ennakko",
    advance_to_info: "Maksa ennakko Handlarfinansille",
    pay_now: "Maksa heti",
    advance: "Ennakko",
    advance_status: {
      paid: "Maksettu",
    },
    payouts_from_handlarfinans: "Maksut Handlarfinansilta",
    dealer_deposit_state: {
      new: "Uusi",
      pending: "Odottaa",
      approved: "Hyväksytty",
      processing: "Käsittelyssä",
      processed: "Maksettu",
      rejected: "Hylätty",
      voided: "Voided",
    },
    payout_task_state: {
      new: "Uusi",
      in_process: "Vireillä",
      processed: "Maksettu",
      cancelled: "Keskeytetty",
      pending: "Odottaa",
      authorized: "Vahvistettu",
      rejected: "Hylätty",
      approved: "Hyväksytty",
      expired: "Vanhentunut",
    },
    advance_payment_success: "Ennakkomaksu suoritettu",
    advance_payment_error: "Ennakkomaksu epäonnistui",
    settlement: {
      success: "Loppuunmaksettu",
      error: "Loppumaksu epäonnistui",
    },
    extend: {
      success: "Sopimusta pidennetty",
      error: "Sopimuksen pidennys epäonnistui",
    },
    payment_link_error: "Maksulinkin luonti epäonnistu, olethan meihin yhteydessä",
  },
  servicing: {
    manual_deposit_success: "Manual deposit was successfully made",
    make_manual_deposit: "Make a manual deposit",
    instant_payment: "Instant payment",
    financials: "Rahoitus",
    service_deal: "Aktivoi sopimus",
    total_purchase_price: "Ostohinta kokonaisuudessaan",
    monthly_interest: "Kuukausikorko",
    financing_amount: "Rahoitusmäärä",
    amount_to_extend: "Määrä pidennystä varten",
    amount_to_settle: "Määrä loppumaksua varten",
    standard_inv_financing: "Varastorahoitus",
    make_payment: "Maksa",
    extend: "Pidennä",
    settle: "Loppumaksu",
    pay_instalment: "Pay instalment",
    amount_due_in_no_data: "Amount due in",
    amount_due_in: "Amount due in {{days}}",
    amount: "Summa",
    purpose: "Purpose",
    upload_payment_receipt: "Lisää maksukuitti",
    payment_receipt_info: "Olet lisännyt maksukuitin. Kun maksu on kirjautunut, pidennys aktivoituu",
    payment_details: "Maksutiedot",
    payment_ref: "Viite",
    iban: "IBAN",
    swift: "SWIFT/BIC",
    bankgiro_id: "Bankgiro ID",
    deposits: "Deposits",
    no_deposits: "No deposits",
    deposit_purpose_options: {
      settlement: "Settlement",
      extend: "Extend",
      advance: "Advance",
      financing: "Financing",
      dividend: "Dividend",
    },
    payment_unavailable: "Please contact support for instructions on how to settle this invoice.",
  },
  section_roles: {
    dealer: "Jälleenmyyjä",
    seller: "Myyjä",
    seller_agent: "Markkinapaikka",
    seller_financier: "Rahoittaja",
    vat_deposit_escrow: "ALV-pantti",
    transferor: "Omistajanvaihdos",
  },
  account: {
    my_account: "Tilini",
    logout: "Kirjaudu ulos",
    email: "Sähköposti",
    name: "Nimi",
    save: "Tallenna",
    update_account_success_message: "Tili päivitetty",
    update_account_error_message: "Päivitys epäonnistui",
  },
  ownership: {
    auth_code: "Varmenne",
    seller_transfers_ownership: "Myyjä tekee luovutusilmoituksen",
    ownership: "Ownership",
    ownership_transfer: "Omistajanvaihdos",
    ownership_papers: "Omistajanvaihdokseen liittyvät dokumentit (vapaaehtoinen)",
    ownership_manual_transfer: "The ownership will be manually transferred",
    ownership_done: "Suoritettu",
    ownership_pending: "Käsittelyssä",
    ownership_transfer_success_message: "Omistajanvaihdos laitettu vireille onnistuneesti",
    ownership_transfer_error_message: "Omistajanvaihdos epäonnistui",
    ownership_control_number_already_exist: "Trade-in transaction already exist",
    ownership_control_number_error: "Invalid control number",
  },
  settled: {
    settled_deal: "Loppulunastettu sopimus",
    transaction_history: "Maksuhistoria",
    purpose: "Tyyppi",
    amount: "Summa",
  },
  date_time: {
    year: "Vuosi",
    years: "vuotta",
    month: "Kuukausi",
    months: "kuukautta",
    weeks: "Viikkoa",
    week: "Viikko",
    day: "Päivä",
    days: "päivää",
    and: "ja",
  },
  support: {
    support: "Tuki",
    unavailable_chat: "Chatti tilapäisesti poissa käytöstä",
    load_more: "Lataa lisää",
  },
  kyc: {
    draft_save_error: "Something went wrong while saving the draft",
    draft_successfully_saved: "Draft was successfully saved",
    form_validation_error: "Please fill in all required fields",
    input_error: "This field is required",
    register_pending_message1: "We are unable to process your request at this moment. Please contact us at ",
    register_pending_message2: " for support",
    application_created_success: "Application was successfully created",
    application_created_error: "Something went wrong while creating application",
    update_application_success: "Hakemus päivitetty",
    update_application_error: "Emme voineet päivittää hakemustanne, olettehan meihin yhteydessä",
    updated_at: "Viimeksi päivitetty",
    terms: {
      title: "Tervetuloa Handlarfinansiin!",
      terms_copy:
        "Handlarfinans toimii Finanssivalvonnan valvonnan alla ja on velvollinen jatkuvasti päivittämään yhteistyökumppaniensa KYC (asiakastuntemus) tietoja. Tämä on tärkeä osa rahanpesun ja terrorismin rahoittamisen vastaista työtä. Pyydämme teitä siksi vastaamaan kysymyksiin ja lähettämään ne meille. Handlarfinans tulee tarkistamaan yrityksen, sen hallituksen, omistajien ja vastuuhenkilöiden tiedot, mukaanlukien luottotiedot Suomen Asiakastiedosta. Rahoituksen hakija vakuuttaa, että hän on saanut luvan yrityksen muilta vastuuhenkilöiltä heidän tietojen tarkastusoikeuden antamista varten.",
      accept_terms: "Hyväksyn yllä olevat ehdot",
    },
    identity: {
      title: "Vahvista henkilöllisyytesi",
      upload_id: "Lisää henkilöllisyystodistus",
      login_with_bankID: "Vahvista henkilöllisyytesi vekkopankkitunnuksilla",
      login_without_bankID: "Jatka ilman verkkopankkitunnuksia",
      id_uploaded_success: "Henkilöllisyystodistus lisätty",
      id_uploaded_error: "Lisääminen epäonnistui",
      replace_id: "Vaihda henkilötodistusasiakirja",
      click_to_replace: "Napsauta kuvaa vaihtaaksesi",
    },
    application: {
      title: "Lähetä hakemus",
      personal_info: "Henkilökohtaiset tiedot",
      finish: "Lähetä",
      company: "Yritys",
      company_question: "Minkä yrityksen puolesta täytät hakemuksen?",
      other: "Muu yritys",
      confirm_company_info: "Vahvista yrityksen tiedot",
      confirm_owner: "Vahvista tosiasiallinen edunsaaja",
      beneficial_owner_pep: "Tosiasiallinen edunsaaja on PEP (Poliittisesti vaikutusvaltainen henkilö)?",
      yes: "Kyllä",
      no: "Ei",
      part_of_group: "Is the company part of a group",
      org_structure: "Describe the org structure",
      group_active_abroad: "Are any of the group entities active abroad",
      org_countries_abroad: "Which countries?",
      owners_years_experience_in_car_sale: "How many years of experience in the car sales industry?",
      purpose: "Mihin tarkoitukseen olet käyttämässä Handlarfinansia?",
      inventory_financing: "Varastorahoitus",
      expansion: "Laajennus",
      debt_financing: "Velkarahoitus",
      marketing_activities: "Markkinointi",
      purpose_other: "Muu syy (kirjoita)",
      vehicle_types_question: "Minkä tyyppisiä ajoneuvoja myyt?",
      passenger_cars: "Henkilöautoja",
      sport_cars: "Urheiluautoja",
      transport_vehicles: "Kuljetusajoneuvoja",
      campers: "Asuntovaunuja & Asuntoautoja",
      snowmobiles_atv: "Moottorikelkkoja & Mönkijöitä",
      trailers: "Perävaunuja",
      purchase_price_range: "Minkä hintaluokan ajoneuvoja ostat?",
      up_to_price: "Up to 300 000 SEK",
      between_price: "300 000 - 500 000 SEK",
      price_and_above: "500 000 SEK and above",
      garage_info: "Mikä on myyntikapasiteettisi?",
      enter_webpage: "Mitä myyntipalstaa käytät ajoneuvojen markkinointiin?",
      online_shop_url: "Linkki nettisivulle",
      enter_store_address: "Missä osoitteessa myytävät ajoneuvot säilytetään?",
      same_as_company_address: "Samassa kuin yrityksen osoite",
      enter_garage_size: "Mikä on hallin ja piha-alueiden koko",
      garage_size: "Halli",
      cars_in_garage: "Halliin mahtuvien ajoneuvojen lukumäärä",
      yard_size: "Piha-alueen koko",
      cars_in_yard: "Pihalle mahtuvien ajoneuvojen lukumäärä",
      about_your_business: "Yrityksestä",
      owner_industry_experience: "Omistajien kokemus alalta (vuosissa)",
      employees_no: "Työntekijöiden lukumäärä (Täysi- ja osa-aika)",
      other_income_sources: "Muut tulolähteet",
      cash_manage: "Käsitteleekö yritys käteistä rahaa?",
      cash_manage_options: {
        frequently: "Yes, with relative frequency",
        as_an_exception: "Yes, as an exception and only when the customer can prove the origin",
        rarely: "Yes, not frequently and only for deposit",
        no: "No",
      },
      vehicle_sourcing_channels: "Where do you buy the majority of vehicles from?",
      vehicle_sourcing_channel: {
        auctions: "Auctions",
        car_dealers: "Car dealers",
        other_companies: "Other companies",
        private_individuals: "Private individuals",
        import: "Import",
      },
      dealership_photos: "Kuvat myyntitiloista",
      dealership_photos_info: "Lisää vähintään yksi kuva hallista, ja toinen piha-alueesta",
      upload_images: "Lisää kuvia",
      budget: "Budjetti",
      estimated_purchase_costs: "Arvioidut sisäänostokulut",
      gross_profit: "Bruttokate",
      expected_turnover: "Odotettu liikevaihto",
      facility_expenses: "Liiketilojen kulut",
      salaries_and_employee_costs: "Henkilöstökulut",
      other_expenses: "Muut kulut",
      expected_result: "Budjetoitu/odotettu tulos",
      board_members: "Hallituksen jäsenet",
      add_another: "Lisää jäsen",
      role: "Rooli",
      holder: "Omistaja",
      commissioner: "Commissioner",
      substitute: "Varajäsen",
      ceo: "TJ",
      deputy_ceo: "Toimitusjohtajan sijainen",
      member: "Jäsen",
      chairman: "Puheenjohtaja",
      alternate_deputy_substitute_ceo: "Varatoimitusjohtajan sijainen",
      executive_member: "Johtava jäsen",
      vice_chairman: "Varapuheenjohtaja",
      percentage_of_ownership: "Omistusosuus",
      ownership_range: {
        0: "0%",
        "0_25": "0-25%",
        "25_50": "More than 25% but not more than 50%",
        "50_75": "More than 50% but not more than 75%",
        "75_100": "More than 75% but not more than 100%",
        full: "100%",
      },
      is_beneficial_owner: "On tosiasiallinen omistaja",
      is_pep: "Is PEP",
      has_additional_citizenship: "Has other citizenship",
      additional_citizenship: "Additional citizenship",
      additional_citizenship_placeholder: "Finland, Norway, ...",
      control_type: "Control type",
      control_type_options: {
        art10:
          "ART 10 - The person has control through shares, participation, memberships, agreements or provisions in for example the articles of association.",
        art14: "ART 14 - The person is a board member or equivalent executive.",
        art20:
          "ART 20 - The person has the right to appoint or remove more than half of the board members or corresponding executives.",
        art30: "ART 30 - The person has control together with close relatives.",
        art40: "ART 40 - The person has control through other companies or associations.",
        art74: "ART 74 - The person exercises control in another way.",
        no_control: "The person has no control.",
      },
    },
    application_submitted: {
      title: "Hyvin tehty!",
      submission_info:
        "Olemme vastaanottaneet hakemuksesi. Käsittelijämme käyvät läpi ja tarkistavat kaikki lähettämäsi tiedot. Olemme taas yhteydessä kun päätös on valmis, tai mikäli tarvitsemme lisätietoja.",
    },
    application_rejected: {
      title: "Application rejected",
      rejection_info:
        "We are sorry to inform you that your application has been rejected. Please contact us at support@handlarfinans.se",
    },
  },
  required_actions: {
    title: "Sinulta vaaditaan toimenpidettä",
    title_plural: "Sinulta vaaditaan {{count}} toimenpidettä",
    description: "Ennen kuin voit tehdä uuden hakemuksen, sinun on suoritettava seuraavat asiat",
    card_title: "Toimenpiteitä vaaditaan",
    no_actions: "Ei vaadittuja toimenpiteitä",
    action_types: {
      ownership_transfer_outstanding: "Omistajanvaihdosta ei ole suoritettu ",
      payment_outstanding: "Sinulla on erääntyneitä sopimuksia",
      credit_limit_exceeded: "Sinulla ei ole limiitissä tilaa",
      contact_support: "Ota yhteyttä tukeen",
      kyc_required: "You have a pending KYC application",
    },
  },
  bank_id_auth: {
    try_again: "Try again",
    authentication_failed: "BankID authentication failed.",
    title: "Tunnistautuminen verkkopankkitunnuksilla",
    error: "Tunnistautuminen verkkopankkitunnuksilla epäonnistui",
    success: "Tunnistautuminen verkkopankkitunnuksilla onnistui!",
    open_app: "Open BankID app",
    hint_messages: {
      RFA1: "Start your BankID app",
      RFA9: "Enter your security code in the BankID app and select Identify or Sign.",
      RFA13: "Trying to start your BankID app.",
      RFA15:
        "Searching for BankID:s, it may take a little while… If a few seconds have passed and still no BankID has been found, you probably don’t have a BankID which can be used for this identification/signing on this computer. If you have a BankID card, please insert it into your card reader. If you don’t have a BankID you can get one from your bank.",
      RFA21: "Identification or signing in progress.",
      RFA23: "Process your machine-readable travel document using the BankID app.",
    },
  },
  transactions: {
    transactions_title: "Suoritetut maksut",
    select_period: "Valitse aikaväli",
    custom: "Mukautettu",
    last_30_days: "Viimeiset 30 päivää",
    current_quarter: "Nykyinen vuosineljännes",
    from_date: "Päivämäärästä (alku)",
    to_date: "Päivämäärään (loppu)",
    generate_transaction_pdf: "Luo maksutiedosto",
    generated_files: "Luodut tiedostot",
    action: "toimenpide",
    file_generated_successfully: "Tiedosto luotu",
    file_generation_error: "Jotain meni pieleen",
  },
  inventory: {
    inventory: "Inventory",
    any_location_status: "Any location status",
    new_vehicle_purchase: "New vehicle purchase",
    edit_vehicle_purchase: "Edit vehicle purchase",
    purchase: "Purchase",
    purchase_price: "Purchase price",
    purchase_price_inc_vat: "Purchase price inc. VAT",
    purchase_date: "Purchase date",
    purchase_date_from_doc: "Purchase date (from receipt/invoice)",
    purchased_by: "Purchased by",
    purchase_note: "Purchase note",
    purchase_doc_label: "Purchase doc",
    purchase_channel: "Purchase channel",
    purchase_information: "Purchase information",
    upload_purchase_doc: "Upload purchase document",
    purchase_doc_sent: "Purchase document sent",
    register_purchase: "Register purchase",
    expected_sale_price: "Expected sale price",
    sale_doc: "Sale doc",
    sale_channel: "Sale channel",
    sold_vehicle_details: "Sold vehicle details",
    sale_information: "Sale information",
    sale_date: "Sale date",
    total_selling_price: "Total selling price",
    sold_with_warranty: "Sold with warranty",
    sold_with_financing: "Sold with financing",
    upload_sale_doc: "Upload sale document",
    marketplace: "Marketplace",
    inventory_vehicle_details: "Inventory vehicle details",
    interest_rate: "Interest rate",
    warranty: "Warranty",
    warranty_provider: "Warranty provider",
    financed: "Financed",
    financier: "Financier",
    customer_type: "Customer type",
    customer_country: "Customer country",
    first_reg_date: "First reg. date",
    vat_status: "VAT status",
    advance_payment: "Advance payment",
    advance_amount: "Advance amount",
    upload_advance_invoice: "Upload advance invoice",
    advance_payment_spec_sent: "Advance payment specification sent",
    total_logistics: "Total logistics",
    total_logistics_net: "Total logistics net",
    total_logistics_vat: "Total logistics vat",
    last_logistic_date: "Last logistic date",
    current_location: "Current location",
    location_status_label: "Location status",
    location_label: {
      dealership: "Dealership",
      in_transit: "In transit",
      repair_shop: "Repair shop",
      other: "Other",
    },
    ownership_status: {
      in_stock: "In stock",
      sold: "Sold",
      stolen: "Stolen",
      scrapped: "Scrapped",
      unknown: "Unknown",
    },
    purchase_doc: {
      invoice: "Invoice",
      purchase_agreement: "Purchase agreement",
    },
    purchase_channel_options: {
      lead_from_private_person: "Lead from private person",
      lead_from_company: "Lead from company",
      blocket_ad_from_car_dealer: "Blocket ad from car dealer",
      blocket_ad_from_other: "Blocket ad from other",
      marketplace: "Marketplace",
      reoccurring_customer: "Reoccurring customer",
    },
    marketplace_options: {
      autorola: "Autorola",
      bca: "BCA",
      kvd: "KVD",
      klaravik: "Klaravik",
    },
    sale_doc_options: {
      car_dealer: "Car dealer",
      private_person: "Private person",
      blocket: "Blocket",
      kvd: "KVD",
      bca: "BCA",
      auto1: "Auto1",
      other: "Other",
      purchase_agreement: "Purchase agreement",
    },
    sale_channel_options: {
      showroom: "Showroom",
      blocket: "Blocket",
      other: "Other",
    },
    ownership_status_filters: {
      all: "All vehicles",
      in_stock: "In inventory",
      sold: "Sold vehicles",
    },
    customer_type_options: {
      private: "Private",
      company: "Company",
    },
    customer_country_options: {
      SE: "Sweden",
      FI: "Finland",
    },
    messages: {
      new_transaction_added: "New transaction was successfully added",
      new_transaction_error: "Something went wrong while adding new transaction",
      vehicle_updated: "Vehicle inventory was successfully updated",
      vehicle_update_failed: "Something went wrong while updating vehicle inventory data",
      location_updated: "Vehicle location was successfully updated",
      location_update_failed: "Something went wrong while updating vehicle location data",
      inventory_vehicle_added: "Vehicle was successfully added to inventory",
    },
    warranty_providers: {
      svensk_bilhandels: "Svensk Bilhandelsförsäkring",
      solid: "Solid",
      fragus: "Fragus",
    },
  },
  register_action: {
    mark_as_sold: "Mark as sold",
    reg_new_action: "Register new action",
    reg_costs: "Register costs",
    update_location: "Update location",
    sell_vehicle: "Sell vehicle",
    cost_type: "Cost type",
    cost_amount: "Cost amount",
    cost_amount_net: "Cost amount net",
    vat_amount: "VAT amount",
    comment: "Comment",
    upload_cost_invoice: "Upload cost invoice",
    vehicle_current_location: "Vehicle current location",
    set_status: "Set status",
    reg_vehicle_sell: "Register vehicle sell",
    cost_types: {
      expenses: "Expenses",
      expense: "Expense",
      reimbursements: "Reimbursements",
      reimbursement: "Reimbursement",
    },
  },
  events: {
    event: "Event",
    events: "Events",
    vehicle: "vehicle",
    vehicle_camel_case: "Vehicle",
    new: "A new",
    a: "A",
    by: "by",
    was_created: "was created",
    was_created_by: "was created by",
    was_deleted: "was deleted by",
    was_updated: "was updated by",
    created_by: "created by",
    deleted_by: "deleted by",
    updated_by: "updated by",
    total_count: "Event log ({{total_count}})",
    load_more: "Load more",
  },
  event_logs: {
    no_logs: "No event logs available",
    no_tags: "No tags available",
  },
  upcoming_events: {
    upcoming_events: "Upcoming events",
    upcoming_payment: {
      message: "Payment of {{amount}} due on the {{date}} for {{vehicle_title}}",
    },
  },
  bank_account_config: {
    bank_representation_type: "Bank representation type",
  },
  payment_plan: {
    show_payment_plan: "Show payment plan",
    payment_plan_title: "Payment plan",
    no_payment_plan: "No payment plan",
    payment_date: "Payment date",
    amount: "Amount",
    interest_amount: "Interest amount",
    principal_amount: "Principal amount",
    remaining_balance: "Remaining balance",
  },
  invoice_details: {
    invoice_title: "Laskun #{{invoiceNo}}",
    invoice_no: "Laskunumero",
    purpose: "Laskun tyyppi",
    total: "Yhteensä",
    status: "Tila",
    net: "Netto",
    vat: "ALV",
    due: "Due",
    balance: "Balance",
    description: "Description",
    invoice_types: {
      advance: "Ennakko",
      financing: "Rahoitus",
      credit_financing: "Hyvityslasku",
      credit_balancing: "Credit balancing",
      interest: "Korkolasku",
    },
    invoice_status: {
      paid: "Maksettu",
      paid_invoice: "Maksettu lasku",
      unpaid: "Maksamaton",
      overdue: "Erääntynyt",
      unbooked: "Unbooked",
      booked: "Booked",
    },
  },
  tags: {
    internal_user: "Internal user",
    external_user: "External user",
    vehicle: "Vehicle",
    file: "File",
    legal_entity: "Contact",
    dealer_credit: "Dealer credit",
    dealer_account: "Dealer account",
    deal: "Deal",
    vehicle_site_audit: "Vehicle site audit",
    kyc_application: "KYC application",
    outbound_email: "Outbound email",
    dealer_location: "Dealer location",
    monitoring_schedule: "Monitoring schedule",
    vehicle_valuation: "Vehicle valuation",
    signing_process: "Signing process",
    payout_task: "Payout task",
    invoice: "Invoice",
    state_assertion: "State assertion",
    see_credit: "See credit",
  },
  deal_validation: {
    invalid_deal: "Invalid deal",
    back_to_dashboard: "Back to dashboard",
    invalid_deal_details: "This vehicle is limited by Handlarfinans policies and can therefore not be financed.",
  },
  suggested_input_value: {
    popover_trigger: "Suggested value popover trigger",
    vat_rate: "VAT rate: {{rate}}%",
    use: "Use",
  },
  onboarding: {
    account_info: "Account information",
    start_info: "Let's start by adding your personal information",
    company_info: "Company information",
    company_info_description: "What company should be connected to this account?",
    no_bank_id: "I don't have a Bank ID",
    continue_with_bank_id: "Continue with Bank ID",
    fi_coming_soon_title: "The sign up process is coming soon for Finland",
    fi_coming_soon_details: "If you want to sign up now, please contact us at laskut@handlarfinans.fi",
    legal_entity_created: "Legal entity was successfully created",
    legal_entity_error: "Something went wrong while creating legal entity",
    se_temp_unavailable_title: "We are unable to process your request at this moment.",
    se_temp_unavailable_details: "Please contact us at bilar@handlarfinans.se for support.",
    no_companies_title: "We failed to find any companies under your control.",
    no_companies_details: "Please contact us at bilar@handlarfinans.se for assistance.",
    continue: "Continue",
    submit_identity_data_error: "Something went wrong while submitting identity data",
    verification_pending_title: "We received your application",
    verification_pending_details:
      "Please allow one of our employees a moment to check all the information you submitted. Once approved, we will notify you via email.",
    verification_rejected_title: "Your identity verification process was rejected",
    verification_rejected_details: "Please contact us at laskut@handlarfinans.fi for support.",
    fill_all_required_fields: "Please fill out all required fields",
  },
  loans: {
    cancel_success: "Loan application was successfully cancelled",
    cancel_error_warning: "Something went wrong while cancelling the loan application",
    cancel_application_warning: "Are you sure you want to cancel the loan application?",
    cancel_application: "Cancel application",
    loan_application: "Loan application",
    product: "Product",
    create_success: "New loan application initiated. We will send the applicant an SMS with next steps.",
    create_error_warning: "Something went wrong while creating the loan application",
    fill_all_fields: "Please fill in all required fields",
    email_or_phone_required: "Please provide a phone number.",
    initiate_application: "Initiate application",
    credit_product: "Credit product",
    loans_title: "Loans",
    applicant_email: "Applicant email",
    applicant_phone_no: "Applicant phone number",
    credit_amount: "Amount",
    new_loan_application: "New loan application",
    new_applicant: "New applicant",
    applicant_info: "Applicant information",
    loan_application_details: "Loan application details",
    loan_details: "Loan details",
    credit_amount_warning: "The amount has to be divisible by 250",
    credit_amount_min_warning: "The amount has to be at least {{min_amount}}",
    credit_amount_max_warning: "The amount has to be at most {{max_amount}}",
    repayment_period_min_warning: "The repayment period has to be at least {{min_period}}",
    repayment_period_max_warning: "The repayment period has to be at most {{max_period}}",
    loan_status: {
      new: "New",
      pending: "Pending",
      approved: "Approved",
      denied: "Denied",
      paid_out: "Paid out",
      error: "Error",
      signed: "Signed",
    },
    filters: {
      all: "All",
      new: "New",
      pending: "Pending",
      manual_check: "Under review",
      approved: "Approved",
      denied: "Denied",
      paid_out: "Paid out",
      error: "Error",
      signed: "Signed",
    },
    product_key: {
      blanco: "Blanco",
    },
    loan_calculator_example: "Loan calculator example",
    years: "yrs",
    repayment_period: "Repayment period",
    monthly_cost: "Monthly cost",
    calculation_example:
      "Calculation example: For a loan of SEK 130,000 that is repaid in 8 years with an interest rate of 10.20%, the effective interest rate is 10.76%. The number of installments is 96 and the monthly payment is SEK 1,986 per month. A setup fee of SEK 495 is added. Total amount to pay SEK 191,191. The interest rate is variable and is an example interest rate based on a typical example from 2024-01-12. The interest rate can vary between 8.98% to 24.99%.",
    no_applications: "No applications",
    dealer_details: "Dealer details",
    financed_by_hf: "Financed by Handlarfinans",
    other: "Other",
    amount_to_settle: "Amount to settle",
    earnings_using_blanco_loan: "Earnings using Blanco loan",
    get_offer: "Get offer",
    settle_with_consumer_loan: "Earn up to {{bonus}} by using Consumer loans to settle this deal.",
    apply_now: "Apply now",
    offer_unavailable: "Offer unavailable",
    offer_fetched: "Loan offer was successfully fetched",
    error_fetching_offer: "Something went wrong while fetching the loan offer",
    offer_type: "Offer type",
    discount_percentage: "Discount percentage",
    discount_amount: "Discount amount",
    amount: "Amount",
  },
  system_messages: {
    info: "Info",
    warning: "Warning",
    error: "Error",
  },
  users_management: {
    page_title: "Users management",
    users: "Users",
    invites: "Invites",
    invite_new_user: "Invite new user",
    invite_user: "Invite user",
    invite_user_success: "The invitation email was successfully sent",
    invite_user_error: "Something went wrong while inviting user",
    resend_invite: "Resend invite",
    resend_invite_success: "The invitation email was successfully resent",
    resend_invite_error: "Something went wrong while resending the invitation email",
    cancel_invite: "Cancel invite",
    cancel_invite_success: "The invitation was successfully cancelled",
    cancel_invite_error: "Something went wrong while cancelling the invitation",
    save_user: "Save user",
    state: "State",
    external_user_state_options: {
      new: "New",
      active: "Active",
      deleted: "Deleted",
    },
    external_user_state_filters: {
      new: "New",
      active: "Active",
    },
    filter_users: "Filter users",
    user_role: "User role",
    select_role: "Select role",
    external_user_roles_options: {
      owner: "Owner",
      employee: "Employee",
      manager: "Manager",
      accountant: "Accountant",
      prospect: "Prospect",
    },
    fetch_roles_error: "Something went wrong while fetching user roles",
    edit_user: "Edit user",
    set_roles_success: "User roles were successfully updated",
    set_roles_error: "Something went wrong while updating user roles",
    revoke_role_success: "Role was successfully removed from user",
    revoke_role_error: "Something went wrong while removing user role",
    grant_role_success: "Role was successfully added to user",
    grant_role_error: "Something went wrong while adding user role",
  },
  dealer_account: {
    edit_info: "Edit account information",
    account_update_success: "Account information was successfully updated",
    account_update_error: "Something went wrong while updating account information",
  },
  notes: {
    note_updated: "Note was successfully updated",
    notes: "Notes",
    deal_notes: "Deal notes",
    add_note: "Add a note",
    successfully_deleted: "Note successfully deleted",
    delete_error: "Note could not be deleted",
    delete_note: "Delete note",
    delete_warning: "You are about to delete this note. Are you sure?",
    cancel: "Cancel",
    delete: "Delete",
    edit: "Edit",
    edit_note: "Edit note",
    load_more: "Load more",
    no_notes: "No notes found yet. Add your own.",
  },
};
